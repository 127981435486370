<template>
	<div class="header">
		<img class="headerimg" src="@/assets/images/directions.jpg" alt="about_us" />
		<div class="headerfont">ABOUT US</div>
	</div>
	<div id="about_us">
		<h2>Our Story</h2>
		<div id="our_story" style="column-count: 1;">
			<p>
				Shady Mountain Market's roots began in the summer of 2007, when I sold baked goods at a local yard sale.
				The baked goods were so popular that I started baking pastries for local stands. Our small bakery began
				in the kitchen and gradually moved into the garage. In 2009, we set up our bakery in a storage shed. My
				husband, local girls, and various nieces helped me bake the many delicious pumpkin rolls, cookies, and
				whoopie pies. We also sold baked goods to Tuck Away Farmstand, Whitehall Craft Show, and more. In the
				summer of 2011, we relocated to Dryville Road and moved the bakery into our basement, while also selling
				baked goods, fresh fruit, and vegetables on Echo Hill’s porch. As our business expanded, we continued to
				hire more employees. In 2013, we erected a storage shed on our property and opened a roadside stand. In
				2017, we finally received our permits to build a combined bakery and store. Thanks to support from
				family and our employees, our new bakery and store opened in October 2017. Our bakery experienced many
				changes throughout its history, but dedication to our customers continues to motivate us to serve you
				the highest quality food.
			</p>
			<!--<p>&nbsp;&nbsp;&nbsp;In the summer of 2007, our street had a yard sale. I decided to sell some baked goods, and did so well selling baked goods there, that I decided to check with some local stands about baking for them. With three small little girls, I didn&#39t want to do much, so I started off with pumpkin rolls, cookies, and whoopie pies. Burkholders in Fleetwood were excited to have someone back for them.</p>
        <p>&nbsp;&nbsp;&nbsp; We started our small little bakery in the kitchen, then gradually into the garage. We then went to Renningers on Saturday mornings, and sold baked goods at Tuck Away Farmstand. We sold lots of baked goods when the Extravaganza was at Renningers. In 2009, we bought a storage shed and set up the bakery in there. Kenny (my husband) helped do lots of baking. We also hired some local girls or nieces to help. We then started to go to Whitehall Craft Show in the spring and fall. We did that until 2016. We were very busy with pumpkin rolls in the fall.</p>
        <p>&nbsp;&nbsp;&nbsp; In July of 2011, we moved to Dryville Road, and moved the bakery into the basement of our home. We then started selling on the porch of Echo Hill every Saturday (and quit selling at Renningers.) We started selling baked goods, then added fresh fruit and veggies. We also started hiring more girls at this time. In 2013, we erected a storage shed on our property and opened up a roadside stand. We also kept selling at Echo Hill on Saturday until 2015. We kept baking in the basement of our home and hauled it all down to the stand. We also started selling some wholesale too.</p>
        <p>&nbsp;&nbsp;&nbsp; Finally in 2017, we got our permits to build a bakery/store combined. We kept the stand open until September and closed down for a month. Our new building opened up October 2nd, 2017. Which we as a family all help out plus quite a few part time employed.
        </p>-->
		</div>
	</div>
</template>
